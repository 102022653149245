import Axios from "axios";

const build_types = ["build", "local"];
const application_status = build_types[0];
const apiBaseURL =
    application_status === "build"
        ? "https://api.imclearning.com/api/"
        : "http://localhost:4000/api/";

const axios = Axios.create({
    baseURL: apiBaseURL,
});

axios.CancelToken = Axios.CancelToken;
axios.interceptors.response.use(
    (res) => res,
    async (error) => {
        if (error.response?.status === 500) {
        }
        // if user is unauthorized
        if (error.response?.status === 401) {
            if (error.request.responseURL != "https://api.imclearning.com/api/auth/login") {
                await localStorage.removeItem("user");
                await localStorage.removeItem("token");
                window.open(window.location.origin, "_self");
            }
        }
        return error.response;
    }
);


export const updateAuthorizationToken = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
};

export default axios;
