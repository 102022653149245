import { useState, useEffect } from "react";
import { checkUserDetails, updateAuthorizationToken } from "services";
import { getUserData } from "utils";
const useApp = () => {
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        const user = getUserData();
        if (Object.keys(user).length > 0) {
            checkUserDetails(user.id).then((result) => {
                if (result.status === 1) {
                    const { token, user } = result.data;
                    if (user?.status) {
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('token', token);
                        updateAuthorizationToken(token);
                        setLoggedIn(true);
                    } else {
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                    }
                    setLoading(false);
                }
            })
        } else {
            setLoading(false);
        }
    }, [])
    return { loading, isLoggedIn }
}
export default useApp;