import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, } from "@/theme/ui/dropdown-menu";
import { ListFilter, PlusCircle, MoreHorizontal, Search, Trash2, Pencil, FileStack } from "lucide-react";
import { Button } from "@/theme/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/theme/ui/table";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle, } from "@/theme/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/theme/ui/avatar"
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious, } from "@/theme/ui/pagination"
import { Badge } from "@/theme/ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/theme/ui/tooltip";
import { Input } from "@/theme/ui/input";
import { getInitials } from "utils";
import AddEditTrainer from "components/add-edit-trainer";
import DeleteTrainerDialog from "components/dialogs/delete-trainer";
import TableLoading from "components/table-loading";
import TrainerDocuments from "components/trainer-documents";
import useTrainers from "./useTrainers";
const TrainersView = () => {
    const {
        models,
        records,
        total,
        currentPageNo,
        refresh,
        onSearch,
        filterBy,
        filterTrainers,
        changePage,
        loading,
        selectedTrainer,
        setSelectedTrainer,
        trainerId,
    } = useTrainers();
    const { addTrainerModel, setAddTrainerModel, deleteTrainerModel, setDeleteTrainerModel, documentModel, setDocumentModel } = models;
    return (
        <>
            <DeleteTrainerDialog refresh={refresh} isOpen={deleteTrainerModel} onClose={setDeleteTrainerModel} />
            {
                documentModel
                    ?
                    <TrainerDocuments
                        refresh={refresh}
                        documents={selectedTrainer?.documents}
                        isOpen={documentModel}
                        onClose={setDocumentModel}
                        checkList={selectedTrainer?.checklist}
                    />
                    :
                    null
            }

            {
                trainerId
                    ?
                    <Card x-chunk="dashboard-06-chunk-0">
                        <CardHeader>
                            <CardTitle>Peronsal Information</CardTitle>
                            <CardDescription>Manage your trainers and view their documents.</CardDescription>
                        </CardHeader>
                    </Card>
                    :
                    <Card className="dark:border-slate-600">
                        <CardHeader>
                            <div className="flex items-center flex-wrap gap-2">
                                <div className="flex flex-col gap-2">
                                    <CardTitle>Trainers</CardTitle>
                                    <CardDescription>
                                        Manage your trainers and view their documents.
                                    </CardDescription>
                                </div>
                                <div className="ml-auto flex items-center gap-2">
                                    <div className="ml-auto flex items-center flex-wrap gap-2">
                                        <div className="relative ml-auto flex-1 md:grow-0">
                                            <Search className="absolute left-2.5 top-2.5 h-3 w-3 text-muted-foreground" />
                                            <Input
                                                type="search"
                                                placeholder="Search..."
                                                onChange={onSearch}
                                                // onKeyPress={(event) => { if (event.key === "Enter") {onSearch(event.target.value)} }}
                                                className="w-full h-8 rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                                            />
                                        </div>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button variant="outline" className="h-8 gap-1">
                                                    <ListFilter className="h-3.5 w-3.5" />
                                                    <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                                                        Filter
                                                    </span>
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent align="end">
                                                <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                                                <DropdownMenuSeparator />
                                                {
                                                    filter_options.map((item) => (
                                                        <DropdownMenuCheckboxItem onClick={() => filterTrainers(item.value)} checked={filterBy === item.value} key={`Trainers Filter ${item.title}`}>{item.title}</DropdownMenuCheckboxItem>
                                                    ))
                                                }
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                        <Button onClick={() => setAddTrainerModel(true)} className="h-8 gap-1">
                                            <PlusCircle className="h-3.5 w-3.5" />
                                            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                                                Add Trainer
                                            </span>
                                        </Button>
                                        {
                                            addTrainerModel
                                                ?
                                                <AddEditTrainer refresh={refresh} isOpen={addTrainerModel} onClose={setAddTrainerModel} />
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent>
                            <div className="relative w-full overflow-auto">
                                <Table>
                                    <TableHeader>
                                        <TableRow className="dark:border-slate-600">
                                            <TableHead className="hidden w-[100px] sm:table-cell">
                                                <span className="sr-only">Image</span>
                                            </TableHead>
                                            {
                                                tableColumns.map((col, index) => (
                                                    <TableHead key={`Trainer Header ${index}`}>{col}</TableHead>
                                                ))
                                            }
                                            <TableHead>
                                                <span className="sr-only">Actions</span>
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {
                                            loading ?
                                                <>
                                                    <TableLoading rows={4} columns={9} />
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            !loading && records && records.map((trainer) => (
                                                <TableRow className="dark:border-slate-600" key={`Trainers Profile ${trainer.id}`}>
                                                    <TableCell className="hidden sm:table-cell">
                                                        {/* <div className="w-[40px] rounded-full overflow-hidden">
                                                    <img src={trainer.profile_image || "https://i.pinimg.com/280x280_RS/e1/08/21/e10821c74b533d465ba888ea66daa30f.jpg"} />
                                                </div> */}
                                                        <Avatar>
                                                            <AvatarImage src={trainer.profile_image} />
                                                            <AvatarFallback>{getInitials(trainer.name)}</AvatarFallback>
                                                        </Avatar>
                                                    </TableCell>
                                                    <TableCell className="font-medium">
                                                        <div className="flex flex-col gap-1">
                                                            <h4>{trainer.name}</h4>
                                                            <Tooltip>
                                                                <TooltipTrigger asChild>
                                                                    <p className="text-xs antialiased text-slate-400 font-normal w-[200px] text-nowrap text-ellipsis overflow-hidden">{trainer.desc}</p>
                                                                </TooltipTrigger>
                                                                <TooltipContent side="right">{trainer.desc}</TooltipContent>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            trainer.is_active
                                                                ?
                                                                <Badge variant="default">Active</Badge>
                                                                :
                                                                <Badge variant="destructive">In Active</Badge>
                                                        }
                                                    </TableCell>
                                                    <TableCell className="hidden md:table-cell">
                                                        {trainer.email}
                                                    </TableCell>
                                                    <TableCell className="hidden md:table-cell">
                                                        {trainer.phone}
                                                    </TableCell>
                                                    <TableCell className="hidden md:table-cell">
                                                        {trainer.country || "NA"}
                                                    </TableCell>
                                                    <TableCell className="hidden md:table-cell">
                                                        {trainer.course}
                                                    </TableCell>
                                                    <TableCell className="hidden md:table-cell">
                                                        {trainer.hourly_rate}
                                                    </TableCell>
                                                    <TableCell>
                                                        <DropdownMenu>
                                                            <DropdownMenuTrigger asChild>
                                                                <Button
                                                                    aria-haspopup="true"
                                                                    size="icon"
                                                                    variant="ghost"
                                                                >
                                                                    <MoreHorizontal className="h-4 w-4" />
                                                                    <span className="sr-only">Toggle menu</span>
                                                                </Button>
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent align="end">
                                                                <DropdownMenuItem
                                                                    onClick={() => { setDocumentModel(trainer.id); setSelectedTrainer(trainer) }}
                                                                    className="flex gap-2"
                                                                >
                                                                    <FileStack className="h-3.5 w-3.5 " />
                                                                    Documents
                                                                </DropdownMenuItem>
                                                                {/* <TrainerDocuments trainer_id={records.id} refresh={refresh} documents={trainer?.documents || []}/> */}
                                                                <DropdownMenuItem onClick={() => setAddTrainerModel(trainer)} className="flex gap-2">
                                                                    <Pencil className="h-3.5 w-3.5 " />
                                                                    Edit
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem onClick={() => setDeleteTrainerModel(trainer.id)} className="flex gap-2 text-rose-600">
                                                                    <Trash2 className="h-3.5 w-3.5 " />Delete
                                                                </DropdownMenuItem>
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </CardContent>
                        <CardFooter>
                            <Pagination>
                                <PaginationContent>
                                    {
                                        currentPageNo !== 1
                                            ?
                                            <PaginationItem onClick={() => changePage(currentPageNo - 1)}>
                                                <PaginationPrevious href="#" />
                                            </PaginationItem>
                                            :
                                            null
                                    }
                                    {
                                        Array.from({ length: total }).map((_, index) => (
                                            <PaginationItem onClick={() => changePage(index + 1)}>
                                                <PaginationLink className="cursor-pointer" isActive={currentPageNo == (index + 1) ? true : false}>{index + 1}</PaginationLink>
                                            </PaginationItem>
                                        ))
                                    }
                                    {
                                        currentPageNo < total
                                            ?
                                            <PaginationItem onClick={() => changePage(currentPageNo + 1)}>
                                                <PaginationNext />
                                            </PaginationItem>
                                            :
                                            null
                                    }
                                </PaginationContent>
                            </Pagination>
                        </CardFooter>
                    </Card>
            }
        </>
    )
}
export default TrainersView;


const tableColumns = [
    "Name",
    "Status",
    "Email",
    "Phone",
    "Country",
    "Course",
    "Hourly Rate",
    // "Actions"
]


const filter_options = [
    { title: "Active", value: true },
    { title: "Not Active", value: false, },
    { title: "All Trainers", value: "all" }
]



