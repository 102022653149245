import { useState } from "react";
import { deleteTrainer } from "services";
import { toast } from "sonner";
const useDeleteTrainer = () => {
    const [loading, setLoading] = useState(false);
    const handleDeleteTrainer = (id, cb = () => { }) => {
        setLoading(true);
        deleteTrainer(id).then((result) => {
            if (result.status === 1) {
                toast.success(result.message, {
                    duration: 2000,
                });
                cb();
            } else {
                toast({
                    variant: "destructive",
                    title: "Something went wrong",
                    description: result.message,
                })
            }
        })
    }
    return { handleDeleteTrainer, loading }
}
export default useDeleteTrainer;