import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "utils";
import { useTheme } from "next-themes"
const useSidebar = () => {
    const { theme, setTheme } = useTheme();
    const { pathname } = window.location;
    const navigate = useNavigate();
    const userInfo = getUserData();
    const { profile_image } = userInfo;
    const [activeRoute, setActiveRoute] = useState(pathname);
    const handleRouteChange = (path) => {
        setActiveRoute(path);
        if (path === "/logout") {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            window.open(window.location.origin, "_self");
        } else {
            navigate(path);
        }
    };
    const handleLogOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.open(window.location.origin, "_self");
    }
    const changeTheme = () => {
        const value = theme === "system" ? "dark" : "system";
        setTheme(value);
    }
    return {
        profile_image,
        handleRouteChange,
        activeRoute,
        handleLogOut,
        theme,
        changeTheme
    }
}
export default useSidebar;