import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter } from "@/theme/ui/sheet";
import { Button } from "@/theme/ui/button";
import { Separator } from "@/theme/ui/separator";
import { Input } from "@/theme/ui/input";
import { Label } from "@/theme/ui/label";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/theme/ui/collapsible";
import { Calendar } from "@/theme/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/theme/ui/popover";
import { ScrollArea } from "@/theme/ui/scroll-area";
import { Badge } from "@/theme/ui/badge"
import { cn } from "@/lib/utils";
import { Plus, CircleCheck, CircleX } from "lucide-react";
import { format } from "date-fns";
import { CalendarIcon, Loader2, Download } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/theme/ui/select";
import moment from "moment";
import useTrainerDocuments from "./useTrainerDocuments";
const TrainerDocuments = ({ isOpen = false, onClose = () => { }, refresh = () => { }, documents = [], checkList = null }) => {
    const { addDoc, setAddDoc, model, handleModelChange, inputRef, handleSaveDocument, loading, errors, profileChecklist, markChecklist } = useTrainerDocuments(checkList);
    const { type, name, expiry_date } = model;
    return (
        <>
            <Sheet open={isOpen ? true : false} onOpenChange={onClose}>
                <SheetContent>
                    <SheetHeader>
                        <SheetTitle>Trainer Documents</SheetTitle>
                        <Separator className="my-2" />
                    </SheetHeader>
                    <ScrollArea className="h-full">
                        <Collapsible open={addDoc} onOpenChange={setAddDoc}>
                            <div className="flex items-center justify-between p-1">
                                <h4 className="text-sm font-semibold">Add Document</h4>
                                <CollapsibleTrigger asChild>
                                    <Button variant="ghost" size="sm" className="w-9 p-0">
                                        <Plus className="h-4 w-4" />
                                        <span className="sr-only">Toggle</span>
                                    </Button>
                                </CollapsibleTrigger>
                            </div>
                            {addDoc ? null : <Separator />}
                            <CollapsibleContent className="p-3 border rounded-md">
                                <div className="flex flex-col gap-4 py-4">
                                    <div className="flex flex-col gap-2">
                                        <Button onClick={() => inputRef.current.click()} variant="secondary" size="sm">Attach File <span class="text-red-600 ms-2">*</span></Button>
                                        <input onChange={(e) => handleModelChange("file", e.target.files[0])} type="file" ref={inputRef} className="hidden" />
                                        {
                                            errors?.file
                                                ?
                                                <span className="text-red-600 text-xs">{errors?.file}</span>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <Label htmlFor="name" >
                                            Document Name <span class="text-red-600">*</span>
                                        </Label>
                                        <Input
                                            onChange={(e) => handleModelChange("name", e.target.value)}
                                            hasError={errors?.name ? true : false}
                                            errorMessage={errors?.name || ""}
                                            id="name"
                                            value={name}
                                            placeholder="Enter Document Name"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <Label htmlFor="name" >
                                            Document Type <span class="text-red-600">*</span>
                                        </Label>
                                        <Select value={type} id="type" onValueChange={(value) => handleModelChange("type", value)}>
                                            <SelectTrigger className="w-full">
                                                <SelectValue placeholder="Please select document type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {
                                                    ["Image", "Pdf", "Word", "PPT", "Excel"].map((coun) => (
                                                        <SelectItem key={`Documents Enums ${coun}`} value={coun}>{coun}</SelectItem>
                                                    ))
                                                }
                                            </SelectContent>
                                        </Select>
                                        {
                                            errors?.type
                                                ?
                                                <span className="text-red-600 text-xs">{errors?.type}</span>
                                                :
                                                null
                                        }

                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <Label htmlFor="name" >
                                            Expiry Date
                                        </Label>
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <Button
                                                    variant={"outline"}
                                                    className={cn(
                                                        "justify-start text-left font-normal w-100",
                                                        !expiry_date && "text-muted-foreground"
                                                    )}
                                                >
                                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                                    {expiry_date ? format(expiry_date, "PPP") : <span>Pick a date</span>}
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0">
                                                <Calendar
                                                    mode="single"
                                                    selected={expiry_date}
                                                    onSelect={(value) => handleModelChange("expiry_date", value)}
                                                    initialFocus
                                                />
                                            </PopoverContent>
                                        </Popover>
                                    </div>
                                </div>
                                <SheetFooter>
                                    <Button disabled={loading} onClick={() => handleSaveDocument(isOpen, () => { refresh(); onClose(false) })} className="w-full">
                                        {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                                        Upload Document
                                    </Button>
                                </SheetFooter>
                            </CollapsibleContent>
                        </Collapsible>
                        <Badge className="pt-1 pb-1 pl-3 pr-3 mt-2 mb-2">Checklist</Badge>
                        <ul class="list-outside flex flex-col p-2 border rounded-md leading-6">
                            {
                                profileChecklist && Object.keys(profileChecklist).map((item) => (
                                    <li className="flex items-center w-full justify-between">
                                        <Label className="text-sm font-medium"> {checklist_enums[item]}</Label>
                                        <Button onClick={() => markChecklist(item, !profileChecklist[item],isOpen,refresh)} size="sm" variant={profileChecklist[item] ? "link_approved" : "link_destructive"}>
                                            {
                                                profileChecklist[item]
                                                    ?
                                                    <CircleCheck className="w-4 h-4" />
                                                    :
                                                    <CircleX className="w-4 h-4" />
                                            }
                                        </Button>
                                    </li>
                                ))
                            }
                        </ul>
                        <Badge className="pt-1 pb-1 pl-3 pr-3 mt-2 mb-2">Uploaded Documents</Badge>
                        <ul class="list-disc list-outside flex flex-col gap-2 ps-2 ">
                            {
                                documents && documents.map((doc) => (
                                    <li className="ms-4" key={`Trainer Uploaded Documents ${doc.id}`}>
                                        <div className="flex justify-between items-center">
                                            <div className="flex flex-col gap-1">
                                                <Label>{doc?.name} </Label>
                                                <Label className="text-xs text-muted-foreground">
                                                    {
                                                        doc.expiry_date
                                                            ?
                                                            moment(doc.expiry_date).format("MMMM Do YYYY")
                                                            :
                                                            "Expiry Date not available"
                                                    }
                                                </Label>
                                            </div>
                                            <Button size="small" variant="link"><Download width={20} /></Button>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </ScrollArea>
                </SheetContent>
            </Sheet>
        </>
    )
}
export default TrainerDocuments;


const checklist_enums = {
    agreement: "Agreement",
    cv: "CV",
    one_page_profile: "One Page Profile",
    passport_size_picture: "Passport Size Picture",
    graduation_certificate: "Grduation Certificate",
    ttt_certificate: "Ttt Certificate",
    passport: "Passport",
    course_certification: "Course Certificate",
    experience_letter: "Experience Letter",
    conduct_certificate: "Conduct Certificate",
    training_clip: "Training Clip",
}