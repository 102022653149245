import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/theme/ui/alert-dialog";
import { Loader2 } from "lucide-react";
import useDeleteTrainer from "./useDeleteTrainer";
const DeleteTrainerDialog = ({ isOpen = false, onClose = () => { }, refresh = () => { } }) => {
    const { handleDeleteTrainer, loading } = useDeleteTrainer();
    return (
        <AlertDialog open={isOpen ? true : false} onOpenChange={onClose}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete the trainer
                        and can be recovered only by the IT team.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={() => handleDeleteTrainer(isOpen, () => { refresh(); onClose(false) })} disabled={loading} className="bg-rose-500 hover:bg-rose-600">
                        {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                        Delete</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default DeleteTrainerDialog;