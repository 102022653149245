import { Tooltip, TooltipContent, TooltipTrigger } from "@/theme/ui/tooltip";
import { Users2, LogOut, Moon, Sun } from "lucide-react";
import useSidebar from "./useSidebar";
import { Button } from "@/theme/ui/button";
import Logo from "assets/images/logo.png";
const Sidebar = () => {
    const { handleRouteChange, activeRoute, handleLogOut, theme, changeTheme } = useSidebar();
    return (
        <>
            <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex dark:border-slate-500">
                <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
                    <img src={Logo} />
                    {
                        routes.map((item) => (
                            <Tooltip key={`Sidebar Item ${item.title}`}>
                                <TooltipTrigger asChild>
                                    {/* <Button onClick={() => handleRouteChange(item.path)} className={`h-[40px] w-[40px] ${activeRoute === item.path ? "bg-primary text-primary-foreground md:text-base" : ""}`} variant="ghost" size="icon">
                                        {item.icon}
                                    </Button> */}
                                    <p
                                        onClick={() => handleRouteChange(item.path)}
                                        className={`flex dark:text-slate-100 cursor-pointer h-9 w-9 items-center justify-center md:h-8 md:w-8 rounded-lg  ${activeRoute === item.path ? "group shrink-0 gap-2 bg-primary text-lg font-semibold text-primary-foreground md:text-base" : "text-muted-foreground transition-colors hover:text-foreground"}`}
                                    >
                                        {item.icon}
                                        <span className="sr-only ">{item.title}</span>
                                    </p>
                                </TooltipTrigger>
                                <TooltipContent side="right">{item.title}</TooltipContent>
                            </Tooltip>
                        ))
                    }
                </nav>
                <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
                    <Button onClick={changeTheme} className="dark:text-slate-100" variant="ghost" size="icon">
                        {
                            theme !== "system"
                                ?
                                <Moon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
                                :
                                <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90" />
                        }
                    </Button>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button onClick={handleLogOut} variant="ghost" size="icon">
                                <LogOut className="h-5 w-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right">Sign Out</TooltipContent>
                    </Tooltip>
                </nav>
            </aside>
        </>
    )
}
export default Sidebar;




const routes = [
    {
        title: "Dashboard",
        path: "/",
        // icon: <Home className="h-5 w-5" />
        icon: <Users2 className="h-5 w-5" />
    },
    // {
    //     title: "Trainers",
    //     path: "/trainers",
    //     icon: <Users2 className="h-5 w-5" />
    // },
    // {
    //     title: "Accrediations",
    //     path: "/accrediations",
    //     icon: <Package className="h-5 w-5" />
    // },
]