import axios from "./axios";
const controller_name = "trainers/";
export const saveTrainer = (data) => {
    let result = axios
        .post(`${controller_name}save`, data)
        .then((res) => {
            return res.data ? res.data : res;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const updateTrainer = (data) => {
    let result = axios
        .post(`${controller_name}update`, data)
        .then((res) => {
            return res.data ? res.data : res;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};


export const getTrainers = (params) => {
    let result = axios
        .get(`${controller_name}get`, {
            params: {
                page: params.page,
                limit: params.limit,
                search: params.search,
                filter: params.filter
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const deleteTrainer = (trainer_id) => {
    let result = axios
        .get(`${controller_name}delete-trainer?id=${trainer_id}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const saveTrainerDocument = (payload) => {
    const formData = new FormData();
    const { name, trainer_id, type, expiry_date, file } = payload;
    formData.append('name', name);
    formData.append('trainer_id', trainer_id);
    formData.append('type', type);
    formData.append('expiry_date', expiry_date);
    formData.append('file', file);
    let result = axios
        .post(`documents/save`, formData)
        .then((res) => {
            return res.data ? res.data : res;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
}


export const updateProfileChecklist = (data) => {
    let result = axios
        .post(`${controller_name}update-profile-checklist`, data)
        .then((res) => {
            return res.data ? res.data : res;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};