import "./index.css";
import { AuthorizationRoutes, ApplicationRoutes } from "routes";
import { Toaster } from "@/theme/ui/sonner"
import { TooltipProvider } from "@/theme/ui/tooltip";
import ErrorBoundary from "components/error-boundary";
import useApp from "./useApp";
import Sidebar from "components/sidebar";
import { QueryClient, QueryClientProvider } from "react-query";
import ThemeProvider from "components/theme-provider";
function App() {
  const { loading, isLoggedIn } = useApp();
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <div className="App">
          <ThemeProvider
            attribute="class"
            defaultTheme="light"
            enableSystem
            disableTransitionOnChange
          >
            {
              isLoggedIn && !loading
                ?
                <>

                  <TooltipProvider>
                    <div className="flex min-h-screen w-full flex-col bg-muted/40">
                      <Sidebar />
                      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
                        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                          <ApplicationRoutes />
                        </main>
                      </div>
                    </div>
                  </TooltipProvider>

                </>
                :
                loading
                  ?
                  <div className="application-loading">
                    <p>Loading</p>
                  </div> :
                  <AuthorizationRoutes />
            }
            <Toaster richColors />
          </ThemeProvider>
        </div>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
