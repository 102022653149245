import { useState } from "react";
import { getTrainers } from "services";
import { useQuery } from "react-query";
import { useSearchParams, useParams } from "react-router-dom";
const useTrainers = () => {
    const { trainerId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [addTrainerModel, setAddTrainerModel] = useState(false);
    const [deleteTrainerModel, setDeleteTrainerModel] = useState(false);
    const [documentModel, setDocumentModel] = useState(false);
    const [selectedTrainer, setSelectedTrainer] = useState([]);
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        limit: searchParams.get("limit") || 10,
        search: searchParams.get("search") || "",
        filter: searchParams.get("filter") || "all",
        total: 0,
    });
    const { isLoading, isError, data, error, refetch } = useQuery({
        queryKey: ["/order-sales-users-view", params],
        queryFn: () => getTrainers(params),
        keepPreviousData: false,
    });
    const handleSearchParams = (key, value) => {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    };
    const onSearch = (e) => {
        const searchValue = e === "" ? "" : e.target.value;
        handleSearchParams("search", searchValue);
        handleSearchParams("page", 1);
        setParams({ ...params, search: searchValue, page: 1 });
    };

    const filterTrainers = (value) => {
        handleSearchParams("filter", value);
        setParams({ ...params, filter: value });
    }

    const changePage = (page, limit) => {
        handleSearchParams("page", page);
        setParams({ ...params, page: page });
    };

    return {
        models: { addTrainerModel, setAddTrainerModel, deleteTrainerModel, setDeleteTrainerModel, documentModel, setDocumentModel },
        records: data?.data?.data || [],
        total: Math.ceil(data?.data?.total / 10),
        loading: isLoading,
        currentPageNo: params.page || 1,
        refresh: () => refetch(),
        onSearch,
        filterBy: params.filter,
        filterTrainers,
        changePage,
        selectedTrainer,
        setSelectedTrainer,
        trainerId,
    }
}
export default useTrainers;
