import { useState } from "react";
import { loginInUser } from "services";
import { toast } from "sonner"
const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState({ email: "", password: "" })
    const onSubmit = () => {
        const payload = { ...model };
        setLoading(true);
        loginInUser(payload).then((result) => {
            setLoading(false);
            if (result.status == 0) {
                toast({
                    variant: "destructive",
                    title: "Something went wrong",
                    description: result.message,
                })
            } else if (result.status === 1) {
                const { user, token } = result.data;
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", token);
                window.location.reload();
            }
        })
    }
    const handleModelChange = (name, value) => {
        setModel({ ...model, [name]: value })
    }
    return { loading, onSubmit, model, handleModelChange }
}
export default useLogin;