import moment from "moment";
export const getUserData = () => {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    return userData;
}

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const validateAll = (model, inputs = []) => {
    let errors = {};
    for (let field in inputs) {
        let fieldErrors = runValidator(
            model[field],
            inputs[field].rules
        );
        let errorMessage = inputs[field].errorMessage;
        if (fieldErrors.length > 0) {
            errors[field] = errorMessage;
        }
    }
    return errors;
};

export const runValidator = (value, validator) => {
    let d1 = moment(),
        d2 = moment();
    let errors = [];
    value = value === null || value === undefined ? "" : value.toString().trim();
    for (let rule in validator) {
        let error = "";
        switch (rule) {
            case "min":
                value = parseFloat(value);

                if (isNaN(value)) {
                    error = rule.error ? rule.error : "Please enter a number. ";
                    errors.push(error);
                } else if (value < parseFloat(validator[rule])) {
                    error = rule.error
                        ? rule.error
                        : "Please enter a value of at-least " + validator[rule] + ". ";
                    errors.push(error);
                }
                break;
            case "max":
                value = parseFloat(value);
                if (isNaN(value)) {
                    error = rule.error ? rule.error : "Please enter a number. ";
                    errors.push(error);
                } else if (value > parseFloat(validator[rule])) {
                    error = rule.error
                        ? rule.error
                        : "Please enter a value of at-most " + validator[rule] + ". ";
                    errors.push(error);
                }
                break;
            case "minDate":
                d1 = moment(value);
                d2 = validator[rule];
                if (d1.isSameOrBefore(d2)) {
                    error = rule.error ? rule.error : "Provide a valid date. ";
                    errors.push(error);
                }
                break;
            case "maxDate":
                d1 = moment(value);
                d2 = validator[rule];
                if (d1.isSameOrAfter(d2)) {
                    error = rule.error ? rule.error : "Provide a valid date. ";
                    errors.push(error);
                }
                break;
            case "minLength":
                if (value.length < validator[rule]) {
                    error = rule.error
                        ? rule.error
                        : "Please enter at-least " + validator[rule] + " characters. ";
                    errors.push(error);
                }
                break;
            case "maxLength":
                if (value.length > validator[rule]) {
                    error = rule.error
                        ? rule.error
                        : "Please enter at-most " + validator[rule] + " characters. ";
                    errors.push(error);
                }
                break;
            case "requiredIndex":
                if (value < 0) {
                    error = rule.error ? rule.error : "Please enter a value. ";
                    errors.push(error);
                }
                break;
            case "required":
                if (validator.required === true && value.length < 1) {
                    error = rule.error ? rule.error : "Please enter a value. ";
                    errors.push(error);
                }
                break;
            case "email":
                if (
                    value !== "" &&
                    //eslint-disable-next-line
                    value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/) === null
                ) {
                    error = rule.error
                        ? rule.error
                        : "Please enter a valid email address. ";
                    errors.push(error);
                }
                break;
            case "url":
                if (
                    value !== "" &&
                    value.match(
                        //eslint-disable-next-line
                        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
                    ) === null
                ) {
                    error = rule.error ? rule.error : "Please enter a valid link. ";
                    errors.push(error);
                }
                break;
            case "name":
                if (value !== "" && value.match(/^[a-zA-Z-\s]+$/) === null) {
                    error = rule.error ? rule.error : "Provide a valid name. ";
                    errors.push(error);
                }
                break;
            case "alphabets":
                if (value !== "" && value.match(/^[a-zA-Z]+$/) === null) {
                    error = rule.error ? rule.error : "Please enter alphabets only. ";
                    errors.push(error);
                }
                break;
            case "alphanumeric":
                if (value !== "" && value.match(/^[a-zA-Z\s0-9]+$/) === null) {
                    error = rule.error
                        ? rule.error
                        : "Aphabets, digits and spaces allowed only. ";
                    errors.push(error);
                }
                break;
            case "regex":
                let regex = validator[rule];
                if (regex.test(value) === false) {
                    error = rule.error ? rule.error : "Kindly fix the formatting. ";
                    errors.push(error);
                }
                break;
            case "pattern":
                if (value !== "" && value.match(validator[rule]) === null) {
                    error = rule.error ? rule.error : "Kindly fix the formatting. ";
                    errors.push(error);
                }
                break;
            case "integer":
                if (Number.isInteger(parseFloat(value)) === false) {
                    error = "Provide a number without decimal points. ";
                    errors.push(error);
                }
                break;
            case "decimal":
                if (isNaN(value) === true) {
                    error = rule.error ? rule.error : "Kindly fix the formatting. ";
                    errors.push(error);
                }
                break;
            default:
                break;
        }
    }
    return errors; // supposed to be errors array
};


export const debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    };
};


export const getInitials = (fullName) => {
       // Split the full name into an array of words
       const nameArray = fullName.trim().split(/\s+/);

       // Map over the array and get the first letter of each word
       const initialsArray = nameArray.map(name => name.charAt(0).toUpperCase());
   
       // Join the initials and slice to get up to 2 characters
       const initials = initialsArray.join('').slice(0, 2);
   
       return initials;
}