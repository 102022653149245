import { Button } from "@/theme/ui/button";
import { Label } from "@/theme/ui/label";
import { Input } from "@/theme/ui/input";
import { Loader2 } from "lucide-react"
import useLogin from "./useLoginView";
import Logo from "assets/images/logo.png";

const LoginView = () => {
    const { loading, onSubmit, handleModelChange } = useLogin();
    return (
        <>
            <div className="relative h-screen flex-col items-center justify-center grid md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
                <div className="h-screen flex flex-col justify-between p-5">
                    <div className="flex justify-between items-center">
                        <div className="relative z-20 flex items-center text-lg font-medium">
                            <img width={40} src={Logo} />
                        </div>
                    </div>
                    <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                        <div className="flex flex-col space-y-2">
                            <h1 className="text-2xl font-semibold tracking-tight">
                                Welcome back!
                            </h1>
                            <p className="text-muted-foreground">
                                Enter your email login to your account
                            </p>
                        </div>
                        <div className="grid gap-4">
                            <div className="grid gap-2">
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    onChange={(e) => handleModelChange("email", e.target.value)}
                                    id="email"
                                    type="email"
                                    placeholder="m@example.com"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <div className="flex items-center">
                                    <Label htmlFor="password">Password</Label>
                                </div>
                                <Input onChange={(e) => handleModelChange("password", e.target.value)} id="password" type="password" required />
                            </div>
                            <Button onClick={onSubmit} disabled={loading} className="w-full">
                                {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                                Login
                            </Button>
                        </div>
                    </div>

                    <div>
                        <blockquote className="space-y-2">
                            <footer className="text-xs opacity-30">Copyright 2024</footer>
                        </blockquote>
                    </div>
                </div>

                <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
                    {/* <div className="absolute inset-0 bg-zinc-900" /> */}
                    <img className="absolute inset-0 object-cover h-full w-full" src="https://samelane.com/wp-content/uploads/2023/05/what-is-refresher-training.jpg" />
                </div>
            </div>
        </>
    )
}

export default LoginView;