import {
    TableCell,
    TableRow,
} from "@/theme/ui/table";
import { Skeleton } from "@/theme/ui/skeleton";
const TableLoading = ({ rows = 3, columns = 2 }) => {
    return (
        <>
            {
                Array.from({ length: rows }).map((_, index) => (
                    <TableRow key={`Table loading Row ${index}`}>
                        {
                            Array.from({ length: columns }).map((_, index) => (
                                <TableCell key={`Table loading Column ${index}`} className="hidden sm:table-cell">
                                    <Skeleton className="w-full h-[50px] " />
                                </TableCell>
                            ))
                        }
                    </TableRow>
                ))
            }
        </>
    )
}
export default TableLoading;