import { Routes, Route } from "react-router-dom";
import LoginView from "views/login";
import DashboardView from "views/dashboard";
import TrainersView from "views/trainers";

export const AuthorizationRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<LoginView />} />
            </Routes>
        </>
    )
}

export const ApplicationRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<TrainersView />} />
                {/* <Route path="/trainers/:trainerId" element={<TrainersView />} />
                <Route path="/trainers" element={<TrainersView />} /> */}
                {/* <Route path="/" element={OrderViewEnum[userRole]} />
                <Route path="/orders" element={OrderViewEnum[userRole]} />
                <Route path="/pmp-students" element={<PmpStudentsView />} />
                <Route path="/currencies" element={<CurrencyView />} /> */}
            </Routes>
        </>
    )
}

