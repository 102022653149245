import axios from "./axios";
const controller_name = "auth/";
export const loginInUser = (data) => {
    let result = axios
        .post(`${controller_name}login`, data)
        .then((res) => {
            return res.data ? res.data : res;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};


export const checkUserDetails = (userId) => {
    let result = axios
        .get(`${controller_name}check-token?id=${userId}`)
        .then((res) => {
            return res.data ? res.data : res;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};