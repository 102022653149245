import { useState } from "react";
import { saveTrainer, updateTrainer } from "services";
import { validateAll } from "utils";
import { toast } from "sonner"
const useAddEditTrainer = (previousModel = {}, type) => {
    const [model, setModel] = useState({
        name: previousModel?.name || "",
        email: previousModel?.email || "",
        phone: previousModel?.phone || "",
        dob: previousModel?.dob || null,
        doj: previousModel?.doj || null,
        course: previousModel?.course || "",
        hourly_rate: previousModel?.hourly_rate || "",
        desc: previousModel?.desc || "",
        country: previousModel?.country || "",
        checklist: { "agreement": null, "cv": null, "one_page_profile": null, "passport_size_picture": null, "graduation_certificate": null, "ttt_certificate": null, "passport": null, "course_certification": null, "experience_letter": null, "conduct_certificate": null, "training_clip": null },
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErros] = useState({});
    const [open, setOpen] = useState(false);
    const handleModelChange = (fieldName, value) => {
        setModel({ ...model, [fieldName]: value })
    }
    const handleSaveTrainer = (cb = () => { }) => {
        const payload = { ...model };
        let validationErrors = validateAll(payload, inputs_validations);
        if (Object.keys(validationErrors).length !== 0) {
            setErros(validationErrors);
        } else {
            setLoading(true);
            let api;
            if (type === "edit") {
                api = updateTrainer;
                payload.id = previousModel.id
            } else {
                api = saveTrainer;
            }
            api(payload).then((result) => {
                if (result.status === 1) {
                    toast.success(result.message, {
                        duration: 2000,
                    })
                    setLoading(false);
                    cb();
                } else {
                    toast({
                        variant: "destructive",
                        title: "Something went wrong",
                        description: result.message,
                    })
                }
            })
        }
    }
    return { model, handleModelChange, handleSaveTrainer, open, setOpen, errors, loading }
}
export default useAddEditTrainer;

const inputs_validations = {
    name: {
        rules: { required: true },
        errorMessage: "Provide a name",
    },
    email: {
        rules: { required: true, email: true },
        errorMessage: "Provide a valid email address",
    },
    phone: {
        rules: { required: true },
        errorMessage: "Provide a phone number",
    },
    course: {
        rules: { required: true },
        errorMessage: "Provide a course",
    },
};

