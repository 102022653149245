import { Button } from "@/theme/ui/button";
import { Input } from "@/theme/ui/input";
import { Label } from "@/theme/ui/label";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter } from "@/theme/ui/sheet";
import { Separator } from "@/theme/ui/separator";
import { Textarea } from "@/theme/ui/textarea";
import { Calendar } from "@/theme/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/theme/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/theme/ui/select";
import { ScrollArea } from "@/theme/ui/scroll-area";
import { cn } from "@/lib/utils";
import { CalendarIcon, Loader2 } from "lucide-react";
import { format } from "date-fns"
import { countries_enums } from "enums";
import useAddEditTrainer from "./useAddEditTrainer";
const AddEditTrainer = ({ isOpen = false, onClose = () => { }, refresh = () => { } }) => {
    const type = typeof isOpen === "object" ? "edit" : "add";
    const { model, handleModelChange, handleSaveTrainer, errors, loading } = useAddEditTrainer(type === "edit" ? isOpen : {}, type);
    const { dob, name, desc, course, phone, hourly_rate, email, country, doj } = model;
    return (
        <>
            <Sheet open={isOpen ? true : false} onOpenChange={onClose}>
                <SheetContent>
                    <SheetHeader>
                        <SheetTitle>Add Trainer</SheetTitle>
                    </SheetHeader>
                    <Separator className="my-2" />
                    <ScrollArea className="h-full pb-6">
                        <div className="flex flex-col gap-4 py-4">
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="name" >
                                    Name <span class="text-red-600">*</span>
                                </Label>
                                <Input
                                    onChange={(e) => handleModelChange("name", e.target.value)}
                                    hasError={errors?.name ? true : false}
                                    errorMessage={errors?.name || ""}
                                    id="name"
                                    value={name}
                                    placeholder="Enter Trainer Name"
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="email" >
                                    Email <span class="text-red-600">*</span>
                                </Label>
                                <Input
                                    onChange={(e) => handleModelChange("email", e.target.value)}
                                    hasError={errors?.email ? true : false}
                                    errorMessage={errors?.email || ""}
                                    id="email"
                                    value={email}
                                    placeholder="Enter trainer email" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="phone" >
                                    Phone <span class="text-red-600">*</span>
                                </Label>
                                <Input
                                    onChange={(e) => handleModelChange("phone", e.target.value)}
                                    hasError={errors?.phone ? true : false}
                                    errorMessage={errors?.phone || ""}
                                    value={phone}
                                    id="phone" placeholder="Enter trainer phone" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="phone" >
                                    Date of Birth
                                </Label>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant={"outline"}
                                            className={cn(
                                                "justify-start text-left font-normal w-100",
                                                !dob && "text-muted-foreground"
                                            )}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {dob ? format(dob, "PPP") : <span>Pick a date</span>}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <Calendar
                                            mode="single"
                                            selected={dob}
                                            onSelect={(value) => handleModelChange("dob", value)}
                                            initialFocus
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="doj" >
                                    Date of Joining
                                </Label>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant={"outline"}
                                            className={cn(
                                                "justify-start text-left font-normal w-100",
                                                !doj && "text-muted-foreground"
                                            )}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {doj ? format(doj, "PPP") : <span>Pick a date</span>}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <Calendar
                                            mode="single"
                                            id="doj"
                                            selected={doj}
                                            onSelect={(value) => handleModelChange("doj", value)}
                                            initialFocus
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="country" >
                                    Country
                                </Label>
                                <Select value={country} id="country" onValueChange={(value) => handleModelChange("country", value)}>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Please select country" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {
                                            countries_enums.map((coun) => (
                                                <SelectItem key={`Counties Enums ${coun}`} value={coun}>{coun}</SelectItem>
                                            ))
                                        }
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="course" >
                                    Course <span class="text-red-600">*</span>
                                </Label>
                                <Input
                                    onChange={(e) => handleModelChange("course", e.target.value)}
                                    hasError={errors?.course ? true : false}
                                    errorMessage={errors?.course || ""}
                                    id="course"
                                    value={course}
                                    placeholder="Enter trainer course" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="rate" >
                                    Hourly Rate with Currency
                                </Label>
                                <Input
                                    value={hourly_rate}
                                    onChange={(e) => handleModelChange("hourly_rate", e.target.value)}
                                    id="rate" placeholder="Enter trainer hourly rate" />
                            </div>
                            {
                                type === "edit"
                                    ?
                                    <div className="flex flex-col gap-2">
                                        <Label htmlFor="status" >
                                            Status
                                        </Label>
                                        <Select defaultValue={isOpen?.is_active} id="status" onValueChange={(value) => handleModelChange("active", value)}>
                                            <SelectTrigger className="w-full">
                                                <SelectValue placeholder="Please select status" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value={true}>Active</SelectItem>
                                                <SelectItem value={false}>In Active</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    :
                                    null
                            }
                            <div className="flex flex-col gap-2">
                                <Label htmlFor="desc" >
                                    Description
                                </Label>
                                <Textarea value={desc} onChange={(e) => handleModelChange("desc", e.target.value)} id="desc" placeholder="Enter trainer description" />
                            </div>
                        </div>
                        <SheetFooter>
                            <Button disabled={loading} onClick={() => handleSaveTrainer(() => { refresh(); onClose(false) })} >
                                {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                                {type === "edit" ? "Edit" : "Save"} Trainer</Button>
                        </SheetFooter>
                    </ScrollArea>
                </SheetContent>
            </Sheet>
        </>
    )
}

export default AddEditTrainer;