export const countries_enums = [
    "United Arab Emirates",
    "Pakistan",
    "India",
    "Germany",
    "Canada",
    "Egypt",
    "France",
    "Oman",
    "United Kingdom",
]