import { useState, useRef } from "react";
import { saveTrainerDocument, updateProfileChecklist } from "services";
import { validateAll } from "utils";
import { toast } from "sonner";
const useTrainerDocuments = (checkList = `{}`) => {
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [addDoc, setAddDoc] = useState(false);
    const [profileChecklist, setProfileChecklist] = useState(JSON.parse(checkList));
    const [model, setModel] = useState({
        name: "",
        type: "",
        expiry_date: "",
        file: null
    });
    const [errors, setErros] = useState({});
    const handleModelChange = (name, value) => {
        setModel({ ...model, [name]: value });
    }
    const handleSaveDocument = (trainer_id, cb = () => { }) => {
        const payload = { ...model, trainer_id };
        let validationErrors = validateAll(payload, inputs_validations);
        if (Object.keys(validationErrors).length !== 0) {
            setErros(validationErrors);
        } else {
            setLoading(true);
            saveTrainerDocument(payload).then((result) => {
                if (result.status === 1) {
                    toast.success(result.message, {
                        duration: 2000,
                    });
                    setModel({
                        name: "",
                        type: "",
                        expiry_date: "",
                        file: null
                    });
                    setLoading(false);
                    setErros({});
                    setAddDoc(false);
                    cb();
                } else {
                    toast({
                        variant: "destructive",
                        title: "Something went wrong",
                        description: result.message,
                    })
                }
            })
        }
    }
    const markChecklist = (key, value, trainer_id, callBack = () => { }) => {
        const payload = {
            ...profileChecklist,
            [key]: value
        };
        toast.promise(updateProfileChecklist({ payload, id: trainer_id }), {
            loading: 'Loading...',
            success: () => {
                setProfileChecklist({ ...profileChecklist, [key]: value });
                callBack();
                return `Checklist Updated Successfully!`;
            },
            error: 'Error',
        });

    }
    return { markChecklist, addDoc, setAddDoc, model, handleModelChange, inputRef, handleSaveDocument, loading, errors, profileChecklist }
}
export default useTrainerDocuments;


const inputs_validations = {
    name: {
        rules: { required: true },
        errorMessage: "Provide a name",
    },
    type: {
        rules: { required: true },
        errorMessage: "Provide a document type",
    },
    file: {
        rules: { required: true },
        errorMessage: "Provide a file",
    },
};